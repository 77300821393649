import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
// import required modules
import { Navigation, Pagination, Keyboard } from "swiper";


import Martina from "../assets/img/Martina.jpg";
import Victoria from "../assets/img/Victoria.jpg";
import Rodrigo from "../assets/img/Rodrigo.jpg";
import Ariel from "../assets/img/Ariel.jpg";
import Ramiro from "../assets/img/Ramiro.jpg";
import Maria from "../assets/img/Maria.jpg";
import Belen from "../assets/img/Belen.jpg";
import Joaquina from "../assets/img/joaquina.jpg";
import Nico from "../assets/img/Nico.jpg";
import Sofia from "../assets/img/Sofia.jpg";
import Caro from "../assets/img/Caro.jpg";
import Mariana from "../assets/img/Mariana.jpg";
import { Image } from "react-bootstrap";

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
export default function Comentarios() {
  const { width } = useWindowDimensions();

  return (
    <div>
      <div className="d-flex flex-wrap justify-content-between pb-5 cursor">
        <Swiper
          grabCursor={true}
          keyboard={{
            enabled: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation, Keyboard]}
          className="mySwiper pb-5"
          slidesPerView={
            width > 1100 ? 2.5 : width > 768 ? 1.75 : width > 500 ? 1.25 : 1
          }
        >
          <SwiperSlide className="d-flex">
            <div className="bg-white card-comentario">
              <div className="d-flex justify-content-center">
                <Image
                  className="w-25 margin-foto-perfil"
                  src={Victoria}
                  roundedCircle
                  fluid
                  alt="FotoCara"
                />
              </div>
              <p className="tamaño-chico text-center">Victoria</p>
              <p className="tamaño-mas-chico text-center text-black-50">
                {" "}
                seguidora de Instagram
              </p>
              <p className="tamaño-chico text-center px-5">
                “Los amo🤍 Hermosa sos una inspiración increíble, ayer estuve
                viendo tus historias destacadas y me motivaste muchísimo, me
                encanta que seas tan eficaz y motivadora, y me siento
                identificada porque hago cosas similares pero ahora te veo a vos
                me queda , muchiiiiisimo por aprender y me encantaria hacerlo
                con vos 💞 se que sos super inspiradora..”
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <div className="bg-white card-comentario">
              <div className="d-flex justify-content-center">
                <Image
                  className="w-25"
                  src={Martina}
                  roundedCircle
                  fluid
                  alt="FotoCara"
                />
              </div>
              <p className="tamaño-chico text-center">Martina</p>
              <p className="tamaño-mas-chico text-center text-black-50">
                {" "}
                seguidor de Instagram
              </p>
              <p className="tamaño-chico text-center px-5">
                “Ena... me encantaría recibir consejos tuyos. Trabajo en una
                consultora. Me encantaría saber como lo manejas vos en el mundo
                de esos negocios... estoy en un cambio de aprendizaje y me senti
                idéntica con tu perfil. Pero vos toda una diosa empoderada...te
                felicito.”
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <div className="bg-white card-comentario">
              <div className="d-flex justify-content-center">
                <Image
                  className="w-25"
                  src={Rodrigo}
                  roundedCircle
                  fluid
                  alt="FotoCara"
                />
              </div>
              <p className="tamaño-chico text-center">Rodrigo</p>
              <p className="tamaño-mas-chico text-center text-black-50">
                {" "}
                seguidor de Instagram
              </p>
              <p className="tamaño-chico text-center px-5">
                “Te mereces todo lo bonito que te pasa y mucho más !!❤️✨ Sos de
                gran inspiración para otros.”
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <div className="bg-white card-comentario">
              <div className="d-flex justify-content-center">
                <Image
                  className="w-25 margin-foto-perfil"
                  src={Maria}
                  roundedCircle
                  fluid
                  alt="FotoCara"
                />
              </div>
              <p className="tamaño-chico text-center">Maria</p>
              <p className="tamaño-mas-chico text-center text-black-50">
                {" "}
                seguidora de Instagram
              </p>
              <p className="tamaño-chico text-center px-5">
                “Creo como te dije una vez… ojala en el mundo existieran mas
                personas como vos…🚀”
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <div className="bg-white card-comentario">
              <div className="d-flex justify-content-center">
                <Image
                  className="w-25"
                  src={Belen}
                  roundedCircle
                  fluid
                  alt="FotoCara"
                />
              </div>
              <p className="tamaño-chico text-center">Belén</p>
              <p className="tamaño-mas-chico text-center text-black-50">
                {" "}
                seguidora de Instagram
              </p>
              <p className="tamaño-chico text-center px-5">
                “Te lo mereces… te admiro un montón! Sos un ser de luz y lo
                transmitís ❤️”
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <div className="bg-white card-comentario">
              <div className="d-flex justify-content-center">
                <Image
                  className="w-25"
                  src={Ariel}
                  roundedCircle
                  fluid
                  alt="FotoCara"
                />
              </div>
              <p className="tamaño-chico text-center">Ariel</p>
              <p className="tamaño-mas-chico text-center text-black-50">
                {" "}
                seguidor de Instagram
              </p>
              <p className="tamaño-chico text-center px-5">
                “Me aparecio esta foto de recuerdo en mi facebook hace unos días
                Es increible tu evolución constante 🌈.”
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <div className="bg-white card-comentario">
              <div className="d-flex justify-content-center">
                <Image
                  className="w-25 margin-foto-perfil"
                  src={Mariana}
                  roundedCircle
                  fluid
                  alt="FotoCara"
                />
              </div>
              <p className="tamaño-chico text-center">Mariana</p>
              <p className="tamaño-mas-chico text-center text-black-50">
                {" "}
                seguidor de Instagram
              </p>
              <p className="tamaño-chico text-center px-5">
                “Sos una bella mujer y se te ve mucho futuro con esas simpleza q
                tenes”
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <div className="bg-white card-comentario">
              <div className="d-flex justify-content-center">
                <Image
                  className="w-25"
                  src={Caro}
                  roundedCircle
                  fluid
                  alt="FotoCara"
                />
              </div>
              <p className="tamaño-chico text-center">Caro</p>
              <p className="tamaño-mas-chico text-center text-black-50">
                {" "}
                seguidora de Instagram
              </p>
              <p className="tamaño-chico text-center px-5">
                “Quiero estar en la academia de educación financiera que estás
                preparando (estrellitas) amo ✨”
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <div className="bg-white card-comentario">
              <div className="d-flex justify-content-center">
                <Image
                  className="w-25"
                  src={Joaquina}
                  roundedCircle
                  fluid
                  alt="FotoCara"
                />
              </div>
              <p className="tamaño-chico text-center">Joaquina</p>
              <p className="tamaño-mas-chico text-center text-black-50">
                {" "}
                seguidora de Instagram
              </p>
              <p className="tamaño-chico text-center px-5">
                “Sos una inspiración, segui asi para adelante… tenés una luz
                super hermosa! Nunca cambies 💖”
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <div className="bg-white card-comentario">
              <div className="d-flex justify-content-center">
                <Image
                  className="w-25"
                  src={Ramiro}
                  roundedCircle
                  fluid
                  alt="FotoCara"
                />
              </div>
              <p className="tamaño-chico text-center">Ramiro</p>
              <p className="tamaño-mas-chico text-center text-black-50">
                {" "}
                seguidor de Instagram
              </p>
              <p className="tamaño-chico text-center px-5">
                “Ena…me encantaría recibir consejos tuyos. Trabajo en una
                consultora. Me encantaria saber como lo manejas vos en el mundo
                de esos negocios…estoy en un cambio de aprendizaje y me sentí
                idénticas con tu perfil Pero vos toda una diosa empoderada..te
                felicito ”
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <div className="bg-white card-comentario">
              <div className="d-flex justify-content-center">
                <Image
                  className="w-25"
                  src={Nico}
                  roundedCircle
                  fluid
                  alt="FotoCara"
                />
              </div>
              <p className="tamaño-chico text-center">Nico</p>
              <p className="tamaño-mas-chico text-center text-black-50">
                {" "}
                seguidor de Instagram
              </p>
              <p className="tamaño-chico text-center px-5">
                “Siempre brillando y con positivismo 💪🏻🥰😘 ”
              </p>
            </div>
                  </SwiperSlide>
                  <SwiperSlide className="d-flex">
            <div className="bg-white card-comentario">
              <div className="d-flex justify-content-center">
                <Image
                  className="w-25"
                  src={Sofia}
                  roundedCircle
                  fluid
                  alt="FotoCara"
                />
              </div>
              <p className="tamaño-chico text-center">Sofía </p>
              <p className="tamaño-mas-chico text-center text-black-50">
                {" "}
                seguidora de Instagram
              </p>
              <p className="tamaño-chico text-center px-5">
                “Me ayudaste un monton con estas palabras ❤️”
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
