import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Autoplay } from "swiper";
import axios from "axios";
import Papa from "papaparse";

function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
        width,
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

export default function Novedades() {
    const { width } = useWindowDimensions();
    const [trabajos, setTrabajos] = useState([]);

    useEffect(() => {
        const getTrabajos = async () => {
            const response = await axios.get(
                "https://docs.google.com/spreadsheets/d/e/2PACX-1vQRMm-ghjAFX5hptM7c4vKBvnecioXaWKE2Y0SBL4fxsHVoYAcZ9gOF0ToCWxxkDg68TQvHQuFyZOL9/pub?gid=0&single=true&output=csv"
            );

            const imagenes = Papa.parse(response.data, { header: true });

            setTrabajos(imagenes.data);
        };
        getTrabajos();
    }, []);
    return (
        <div className="color-gris-fondo color-negro py-5">
            <div className={width <= 1200 ? "" : "container"}>
                <div className="d-flex justify-content-center flex-wrap mb-3 mx-5">
                    <h2 className="peso-bold color-negro me-2">Novedades</h2>
                    <h2 className="peso-bold color-dorado ">ENACOIN</h2>
                </div>
                <p className="color-gris-oscuro peso-regular2 text-center mb-5 mx-4">
                    Mantente actualizado de lo que pasa en el Mundo Crypto.
                    Todas las semanas <br /> encontrarás un resumen de las
                    noticias de todo el mundo.
                </p>
                <div className="d-flex flex-wrap cursor">
                    <Swiper
                        modules={[Navigation, Autoplay]}
                        navigation={true}
                        autoplay={{
                            delay: 3600,
                            disableOnInteraction: false,
                        }}
                        spaceBetween={10}
                        slidesPerView={
                            width > 1100
                                ? 3
                                : width > 768
                                ? 2.5
                                : width > 500
                                ? 1.75
                                : 1.25
                        }
                        className=""
                    >
                        {trabajos.map((trabajo) => (
                            <SwiperSlide>
                                <Card className="card-novedades mx-auto mb-5">
                                    <Card.Img
                                        className="image-card"
                                        variant="top"
                                        src={trabajo.imagenCuadrada}
                                        alt="Imagen no encontrada"
                                    />
                                    <Card.Body className="m-2 d-flex flex-column justify-content-between">
                                        <Card.Title className="mb-4">
                                            {trabajo.titulo.length < 65 ? (
                                                <p className="color-negro peso-semibold2 tamaño-intermedio">
                                                    {trabajo.titulo}
                                                </p>
                                            ) : (
                                                <p className="color-negro peso-semibold2 tamaño-intermedio">
                                                    {trabajo.titulo.substring(
                                                        0,
                                                        60
                                                    )}
                                                    ...
                                                </p>
                                            )}
                                        </Card.Title>
                                        <p className="tamaño-mas-chico">
                                            {trabajo.fecha}
                                        </p>
                                        <Card.Text className=" mt-2 mb-4">
                                            {trabajo.descripcion.length <
                                            120 ? (
                                                <p className="color-gris peso-regular2 tamaño-mas-chico">
                                                    {trabajo.descripcion}
                                                </p>
                                            ) : (
                                                <p className="color-gris peso-regular2 tamaño-mas-chico">
                                                    {trabajo.descripcion.substring(
                                                        0,
                                                        117
                                                    )}
                                                    ...
                                                </p>
                                            )}
                                        </Card.Text>
                                        <Link to={"/novedades/" + trabajo.id}>
                                            <Button className="boton-ver-mas2 border-0 ">
                                                <p className="mb-0 tamaño-chico peso-semibold2">
                                                    Ver más
                                                </p>
                                            </Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}
