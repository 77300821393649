import React from "react";
import { Button, Carousel, Image } from "react-bootstrap";
import carouselimg from "../assets/img/carouselimg.png";
import { Link } from "react-router-dom";
import wsp from "../assets/img/btnwsp.svg";
import horizontal1 from "../assets/img/horizontal1.jpg";
import horizontal2 from "../assets/img/horizontal2.jpg";
import horizontal3 from "../assets/img/horizontal3.jpg";
import horizontal4 from "../assets/img/horizontal4.jpg";
import vertical1 from "../assets/img/VERTICAL1.jpg";
import vertical2 from "../assets/img/VERTICAL2.jpg";
import vertical3 from "../assets/img/VERTICAL3.jpg";
import vertical4 from "../assets/img/VERTICAL4.jpg";

export const ProximosEventos = () => {
    return (
        <div className="color-negro-fondo color-blanco" id="master">
            <div className="container">
                <div className="pb-5 pt-4 mt-4 text-center">
                    <p className="espaciado">PRÓXIMOS</p>
                    <h2 className="peso-semibold color-dorado ">Eventos</h2>
                </div>
                <Carousel className="" indicators={false} interval={null}>
                    <Carousel.Item>
                        <div className="px-5">
                            <div className="d-flex flex-wrap imagen-carousel align-items-center">
                                <div className="col-12 col-lg-6 p-lg-5 p-0 d-flex justify-content-center">
                                    <Image
                                        className=""
                                        src={carouselimg}
                                        alt="slide 1"
                                        fluid
                                    />
                                </div>
                                <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
                                    <h3 className="peso-semibold2 mt-lg-0 mt-4">
                                        Master Class
                                    </h3>
                                    <p className="peso-semibold2 tamaño-chico color-gris">
                                        Criptomonedas para principiantes
                                    </p>
                                    <p className="peso-regular2 color-naranja tamaño-chico">
                                        streaming en vivo
                                    </p>
                                    <p className="peso-semibold2 tamaño-chico color-gris">
                                        12 Oct 2022 . 18:30 - 21:00
                                    </p>
                                    <p className="d-sm-block d-none peso-regular2 tamaño-chico color-gris me-lg-5 me-0">
                                        Te voy a enseñar mis estrategias para
                                        aumentar tus ingresos a través del
                                        trading y a diversificar inversiones
                                        para que puedas lograr tu libertad
                                        financiera. Será una transmisión en vivo
                                        a través de 3 plataformas en simultáneo,
                                        con cupos limitados para darle a cada
                                        participante la posibilidad de
                                        interactuar conmigo y con mi equipo de
                                        trabajo.
                                    </p>
                                    <p className="d-block d-sm-none peso-regular2 tamaño-chico color-gris me-lg-5 me-0">
                                        Te voy a enseñar mis estrategias para
                                        aumentar tus ingresos a través del
                                        trading y a diversificar inversiones
                                        para que puedas lograr tu libertad
                                        financiera. Será una transmisión en vivo
                                        a través de 3 plataformas en simultáneo,
                                        con cupos limitados...{" "}
                                        <Link
                                            to="/masterclass"
                                            className="color-naranja text-nowrap"
                                        >
                                            {" "}
                                            Seguir leyendo
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="px-sm-5 px-0 imagen-carousel d-flex align-items-center">
                            <Image
                                className="d-none d-lg-block "
                                src={horizontal1}
                                alt="slide 1"
                                fluid
                            />
                            <Image
                                className="d-lg-none d-block"
                                src={vertical1}
                                alt=" slide 1"
                                fluid
                            />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="px-sm-5 px-0 imagen-carousel d-flex align-items-center">
                            <Image
                                className="d-none d-lg-block "
                                src={horizontal2}
                                alt=" slide 2"
                                fluid
                            />
                            <Image
                                className="d-lg-none d-block"
                                src={vertical2}
                                alt=" slide 2"
                                fluid
                            />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="px-sm-5 px-0 imagen-carousel d-flex align-items-center">
                            <Image
                                className="d-none d-lg-block "
                                src={horizontal3}
                                alt=" slide 3"
                                fluid
                            />
                            <Image
                                className="d-lg-none d-block"
                                src={vertical3}
                                alt=" slide 3"
                                fluid
                            />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="px-sm-5 px-0 imagen-carousel d-flex align-items-center">
                            <Image
                                className="d-none d-lg-block "
                                src={horizontal4}
                                alt=" slide 4"
                                fluid
                            />
                            <Image
                                className="d-lg-none d-block"
                                src={vertical4}
                                alt=" slide 4"
                                fluid
                            />
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div>
                <div className="">
                    <div className="d-flex justify-content-center my-5">
                        <div className="">
                            <a
                                href="https://www.instagram.com/enacoin/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button className="boton-contactar-wsp border-0">
                                    <Image
                                        className="mx-1 mb-1"
                                        src={wsp}
                                        fluid
                                        alt="icono"
                                    />
                                    <span className="mb-0 tamaño-chico peso-semibold2">
                                        Contactar
                                    </span>
                                </Button>
                            </a>
                        </div>
                        <div className="ms-2">
                            <Link to="/masterclass">
                                <Button className="boton-ver-mas">
                                    <p className="mb-0 tamaño-chico peso-semibold2">
                                        Ver más
                                    </p>
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
