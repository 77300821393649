import React from "react";
import { Card, Image } from "react-bootstrap";
import modelo from "../assets/img/modelo.png";
import { BotonContacto } from "./BotonContacto";
import SwiperTips from "./SwiperTips";
export default function Enacointips() {
    return (
        <div className="color-negro-fondo pt-4 color-blanco">
            <div className="pt-5 mt-5">
                <div className="d-flex justify-content-center flex-wrap mb-5 container px-4">
                    <h3 className="peso-semibold color-blanco me-2">
                        Segui nuestros
                    </h3>
                    <h3 className="peso-semibold color-dorado ">
                        #Enacointips
                    </h3>
                </div>
                <div className="">
                    <SwiperTips />
                </div>
            </div>
            <div className="d-flex flex-column flex-sm-row container px-4 pt-5" id="nosotros">
                <div className="col-12 d-block d-sm-none mb-5">
                    <Image
                        className="imagen-model"
                        src={modelo}
                        fluid
                        alt="Modelo"
                    />
                </div>
                <div className=" col-sm-6 col-12 d-flex flex-column justify-content-center" >
                    <div className="text-start">
                        <p className="espaciado mb-0">ACERCA DE</p>
                        <h2 className="peso-semibold color-dorado ">ENACOIN</h2>
                    </div>
                    <p className="peso-regular2 tamaño-chico color-gris mt-4 mb-5 me-sm-5 me-0">
                        Soy una persona apasionada, simple y tenaz, me gusta
                        inspirar y generar cambios en los demás, una eterna
                        estudiante, siempre con ganas de innovar. Después de
                        haber arriesgado tanto, logré entender que la educación
                        es la inversión más segura y rentable que una
                        persona puede hacer.
                        <br />
                        <br /> Mi propósito es inspirarlos a mejorar sus
                        finanzas y autoestima a través de mis conocimientos,
                        habilidades y herramientas.
                    </p>
                    <BotonContacto />
                </div>
                <div className="col-6 d-sm-flex d-none justify-content-end">
                    <div className="d-flex align-items-start">
                        <Image
                            className="imagen-model"
                            src={modelo}
                            fluid
                            alt="Modelo"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
