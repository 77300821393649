import { Image, Nav } from "react-bootstrap";
import logofooter from "../assets/img/logo.png";
import { BotonContacto } from "./BotonContacto";
import face from "../assets/img/face.svg";
import ig from "../assets/img/ig.svg";
import tw from "../assets/img/tw.svg";
import yt from "../assets/img/yt.svg";
import heart from "../assets/img/heart.svg";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

export default function Footer() {
  return (
    <div className="mt-auto">
      <div className=" text-white color-negro-fondo ">
        <div className="px-4 container ">
          <div className="py-5">
            <hr />
          </div>
          <div className="row text-center">
            {/* Column1 */}
            <div className="col-12 col-xl-3 text-sm-center d-flex  justify-content-center mx-auto py-4 mt-3 mt-md-0 ">
              <ul className="list-unstyled">
                <li>
                  <Image
                    className="w-100"
                    src={logofooter}
                    alt="logoHotel"
                    fluid
                  />
                </li>
                <div className="d-flex justify-content-around mt-2">
                  <a
                    className="sin-sub mx-1"
                    href="https://www.instagram.com/enacoin/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image className="w-100" src={ig} alt="logoHotel" fluid />
                  </a>
                  <a
                    className="sin-sub mx-1"
                    href="https://www.facebook.com/profile.php?id=100084742819402"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image className="w-100" src={face} alt="logoHotel" fluid />
                  </a>
                  <a
                    className="sin-sub mx-1"
                    href="https://twitter.com/oficialenacoin?t=WrVbb3FA93g-Hs1DO83yew&s=08"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image className="w-100" src={tw} alt="logoHotel" fluid />
                  </a>
                  <a
                    className="sin-sub mx-1"
                    href="https://www.youtube.com/channel/UCLY-uoNk7I-EKgL4cokT2Ow/featured"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image className="w-100" src={yt} alt="logoHotel" fluid />
                  </a>
                </div>
              </ul>
            </div>
            {/* Column2 */}
            <div className="col-12 col-xl-3 text-md-center d-flex py-4 justify-content-center mx-auto footer-center mt-3 ">
              <ul className="list-unstyled">
                <li>
                  <NavHashLink className="sin-sub text-white" to="/#cursos">
                    <p className="tamaño-chico mt-2 mb-0">
                      Cursos online <br />
                    </p>
                  </NavHashLink>

                  <NavHashLink as={Link} className="sin-sub text-white" to="/#master">
                    <p className="tamaño-chico mt-2 mb-0">
                      Master Class <br />
                    </p>
                  </NavHashLink>

                  <NavHashLink className="sin-sub text-white" to="/#nosotros">
                    <p className="tamaño-chico mt-2 mb-0">Nosotros</p>
                  </NavHashLink>
                  {/* <Link className="sin-sub text-white" to="/#nosotros">
                    <p className="tamaño-chico mt-2 mb-0">
                      Nosotros <br />
                    </p>
                  </Link> */}
                </li>
              </ul>
            </div>
            {/* Column3 */}
            <div className="col-12 col-xl-3 text-md-center d-flex justify-content-center mx-auto mt-3 py-4 ">
              <ul className="list-unstyled">
                <li>
                  <Link
                    className="sin-sub text-white"
                    to="/preguntasfrecuentes"
                  >
                    <p className="tamaño-chico  mt-2 mb-0">
                      Terminos y condiciones
                    </p>
                  </Link>
                  <Link
                    className="sin-sub text-white"
                    to="/preguntasfrecuentes"
                  >
                    <p className="tamaño-chico  mt-2 mb-0">
                      Politicas de privacidad
                    </p>
                  </Link>
                  <Link
                    className="sin-sub text-white"
                    to="/preguntasfrecuentes"
                  >
                    <p className="tamaño-chico   mt-2 mb-0">
                      Preguntas frecuentes
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
            {/* Column4 */}
            <div className="col-12 col-xl-3 text-md-center d-flex justify-content-center mx-auto mt-3 py-4 ">
              <ul className="list-unstyled">
                <li className="tamaño-medio">
                  <p className="tamaño-medio">¿Necesitas ayuda?</p>
                  <p className="tamaño-medio color-dorado peso-semibold2 ">
                    ¡Hablemos!
                  </p>
                  <BotonContacto />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
          <hr />
        </div>
        <div className="py-4 color-negro-fondo container footer-center-texto ">
          <div className="row ">
            <div className="col-12 col-md-9">
              <div className="text-white-50">© Copyright 2022 ENACOIN</div>
            </div>
            <div className="col-12 col-md-3">
              <div className="text-white-50">
                <div className="peso-regular text-white-50">
                  Hecho con{" "}
                  <span>
                    <img className="mx-2" src={heart} alt="heart"></img>
                  </span>{" "}
                  por{" "}
                  <a
                    className="color-dorado sin-sub"
                    href="https://lemons.agency/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Lemons Agency
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
