import React from "react";
import carouselimg from "../assets/img/carouselimg.png";
import stars from "../assets/img/star.png";
import { Button, Image } from "react-bootstrap";
import wsp from "../assets/img/btnwsp.svg";

export default function BannerMasterClass() {
  return (
    <div className=" py-5 color-negro-fondo">
      {/* desktop  */}
      <div className="d-none d-lg-block">
        <div className="container my-5">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h2 className="peso-bold tamaño-mas-grande  text-white mt-lg-0 mt-4">
                {" "}
                Master <br /> Class
              </h2>
              <p className="peso-semibold2 tamaño-chico color-gris">
              Criptomonedas para principiantes
              </p>
              <div className="d-flex my-2">
                <Image
                  className="d-block px-2"
                  src={stars}
                  alt="First slide"
                  fluid
                />
                <Image
                  className="d-block px-2"
                  src={stars}
                  alt="First slide"
                  fluid
                />
                <Image
                  className="d-block px-2"
                  src={stars}
                  alt="First slide"
                  fluid
                />
                <Image
                  className="d-block px-2"
                  src={stars}
                  alt="First slide"
                  fluid
                />
                <Image
                  className="d-block px-2"
                  src={stars}
                  alt="First slide"
                  fluid
                />
              </div>
            

              <p className="peso-semibold2 tamaño-chico color-gris">
                12 Oct 2022 . 18:30 - 21:00
              </p>
              <p className="peso-regular2 tamaño-chico color-gris me-lg-5 me-0">
                Te voy a enseñar mis estrategias para aumentar tus ingresos a
                través del trading y a diversificar inversiones para que puedas
                lograr tu libertad financiera.
              </p>
              <p className="peso-regular2 tamaño-chico color-gris me-lg-5 me-0">
                Será una transmisión en vivo a través de 3 plataformas en
                simultáneo, con cupos limitados para darle a cada participante
                la posibilidad de interactuar conmigo y con mi equipo de
                trabajo.
              </p>
              <div className="d-flex">
                <div className="me-2">
                  <a
                    href="https://www.instagram.com/enacoin/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button className="boton-contactar-mc-incribirme  border-0">
                      <p className="mb-0 tamaño-chico peso-semibold2">
                        Inscribirme
                      </p>
                    </Button>
                  </a>
                </div>
                <div className="mx-2">
                  <a
                    href="https://www.instagram.com/enacoin/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button className="boton-contactar-wsp border-0">
                      <Image className="mx-1 mb-1" src={wsp} alt="icono" />
                      <span className="tamaño-chico peso-semibold2 ">
                        Contactar
                      </span>
                    </Button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 ">
              <Image
                className="d-block  imagen-carousel "
                src={carouselimg}
                alt="First slide"
                fluid
              />
            </div>
          </div>
        </div>
      </div>
      {/* mobile */}
      <div className="d-lg-none d-block">
        <div className="d-flex justify-content-center">
          <div className="col-12 ">
            <Image
              className="d-block w-100 px-5"
              src={carouselimg}
              alt="First slide"
              fluid
            />
          </div>
        </div>
        <div className="container py-2">
          <h2 className="peso-bold tamaño-mas-grande  text-white mt-lg-0 mt-4">
            {" "}
            Master <br /> Class
          </h2>
          <p className="peso-semibold2 tamaño-chico color-gris">
              Criptomonedas para principiantes
              </p>
          <div className="d-flex my-2">
            <Image
              className="d-block px-2"
              src={stars}
              alt="First slide"
              fluid
            />
            <Image
              className="d-block px-2"
              src={stars}
              alt="First slide"
              fluid
            />
            <Image
              className="d-block px-2"
              src={stars}
              alt="First slide"
              fluid
            />
            <Image
              className="d-block px-2"
              src={stars}
              alt="First slide"
              fluid
            />
            <Image
              className="d-block px-2"
              src={stars}
              alt="First slide"
              fluid
            />
          </div>

          <p className="peso-semibold2 tamaño-chico color-gris">
            12 Oct 2022 . 18:30 - 21:00
          </p>
          <p className="peso-regular2 tamaño-chico color-gris me-lg-5 me-0">
            Te voy a enseñar mis estrategias para aumentar tus ingresos a través
            del trading y a diversificar inversiones para que puedas lograr tu
            libertad financiera.
          </p>
          <p className="peso-regular2 tamaño-chico color-gris me-lg-5 me-0">
            Será una transmisión en vivo a través de 3 plataformas en
            simultáneo, con cupos limitados para darle a cada participante la
            posibilidad de interactuar conmigo y con mi equipo de trabajo.
          </p>
          <div className="d-flex">
            <div className="me-2">
              <a
                href="https://www.instagram.com/enacoin/"
                target="_blank"
                rel="noreferrer"
              >
                <Button className="boton-contactar-mc-incribirme  border-0">
                  <p className="mb-0 tamaño-chico peso-semibold2">
                    Inscribirme
                  </p>
                </Button>
              </a>
            </div>

            <div className="mx-2">
              <a
                href="https://www.instagram.com/enacoin/"
                target="_blank"
                rel="noreferrer"
              >
                <Button className="boton-contactar-wsp border-0">
                  <Image className="mx-1 mb-1" src={wsp} fluid alt="icono" />
                  <span className="mb-0 tamaño-chico peso-semibold2">
                    Contactar
                  </span>
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
