import React from "react";
import FormContacto from "./FormContacto";

export const Contacto = () => {
  return (
    <div className=" color-negro-fondo text-white py-4 my-4" id="contacto">
      <div className="py-4 color-negro-fondo container   ">
        <div className="tamaño-chico d-flex row">
          <div className="col-12 col-xl-4 pt-5">
            <h3 className="peso-bold ">¿Necesitas ayuda?</h3>
            <h3 className="peso-bold color-dorado ">¡Hablemos!</h3>
          </div>
          <div className="col-12 col-xl-8">
            <FormContacto />
          </div>
        </div>
      </div>
    </div>
  );
};
