import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Card } from "react-bootstrap";
import { Navigation, Keyboard } from "swiper";

function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
        width,
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}
export default function SwiperTips() {
    const { width } = useWindowDimensions();

    return (
        <Swiper
            grabCursor={true}
            keyboard={{
                enabled: true,
            }}
            navigation={true}
            modules={[Navigation, Keyboard]}
            spaceBetween={10}
            slidesPerView={
                width > 1440
                    ? 5.75
                    : width > 1024
                    ? 3.75
                    : width > 768
                    ? 2.75
                    : 1.75
            }
            className="enatips"
        >
            <SwiperSlide>
                <Card className="card-tips mx-auto mb-5 ">
                    <Card.Body className="mx-2 mt-2 ">
                        <Card.Title
                            className="color-negro peso-light"
                            style={{ fontSize: "32px" }}
                        >
                            #1
                        </Card.Title>
                        <Card.Text className="tamaño-chico mt-2 color-gris peso-regular2 ">
                            Hold o Hodl es comprar y esperar.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </SwiperSlide>
            <SwiperSlide>
                <Card className="card-tips mx-auto mb-5 ">
                    <Card.Body className="mx-2 mt-2 ">
                        <Card.Title
                            className="color-negro peso-light"
                            style={{ fontSize: "32px" }}
                        >
                            #2
                        </Card.Title>
                        <Card.Text className="tamaño-chico mt-2 color-gris peso-regular2 ">
                            Rodéate de un entorno que te potencie
                        </Card.Text>
                    </Card.Body>
                </Card>
            </SwiperSlide>
            <SwiperSlide>
                <Card className="card-tips mx-auto mb-5 ">
                    <Card.Body className="mx-2 mt-2 ">
                        <Card.Title
                            className="color-negro peso-light"
                            style={{ fontSize: "32px" }}
                        >
                            #3
                        </Card.Title>
                        <Card.Text className="tamaño-chico mt-2 color-gris peso-regular2 ">
                            Entrena tu mente para la abundancia
                        </Card.Text>
                    </Card.Body>
                </Card>
            </SwiperSlide>
            <SwiperSlide>
                <Card className="card-tips mx-auto mb-5 ">
                    <Card.Body className="mx-2 mt-2 ">
                        <Card.Title
                            className="color-negro peso-light"
                            style={{ fontSize: "32px" }}
                        >
                            #4
                        </Card.Title>
                        <Card.Text className="tamaño-chico mt-2 color-gris peso-regular2 ">
                            Crea un fondo de emergencia
                        </Card.Text>
                    </Card.Body>
                </Card>
            </SwiperSlide>
            <SwiperSlide>
                <Card className="card-tips mx-auto mb-5 ">
                    <Card.Body className="mx-2 mt-2 ">
                        <Card.Title
                            className="color-negro peso-light"
                            style={{ fontSize: "32px" }}
                        >
                            #5
                        </Card.Title>
                        <Card.Text className="tamaño-chico mt-2 color-gris peso-regular2 ">
                            Enfócate en generar ingresos pasivos
                        </Card.Text>
                    </Card.Body>
                </Card>
            </SwiperSlide>
            <SwiperSlide>
                <Card className="card-tips mx-auto mb-5 ">
                    <Card.Body className="mx-2 mt-2 ">
                        <Card.Title
                            className="color-negro peso-light"
                            style={{ fontSize: "32px" }}
                        >
                            #6
                        </Card.Title>
                        <Card.Text className="tamaño-chico mt-2 color-gris peso-regular2 ">
                            Diversificar para generar buena rentabilidad
                        </Card.Text>
                    </Card.Body>
                </Card>
            </SwiperSlide>
            <SwiperSlide>
                <Card className="card-tips mx-auto mb-5 ">
                    <Card.Body className="mx-2 mt-2 ">
                        <Card.Title
                            className="color-negro peso-light"
                            style={{ fontSize: "32px" }}
                        >
                            #7
                        </Card.Title>
                        <Card.Text className="tamaño-chico mt-2 color-gris peso-regular2 ">
                            Se flexible a los cambios
                        </Card.Text>
                    </Card.Body>
                </Card>
            </SwiperSlide>
            <SwiperSlide>
                <Card className="card-tips mx-auto mb-5 ">
                    <Card.Body className="mx-sm-2 mx-0 mt-sm-2 mt-0 pt-sm-3 pt-2">
                        <Card.Title
                            className="color-negro peso-light mb-0"
                            style={{ fontSize: "32px" }}
                        >
                            #8
                        </Card.Title>
                        <Card.Text className="tamaño-chico mt-sm-2 mt-0 color-gris peso-regular2 ">
                            Stacking inteligente es una buena manera de generar
                            ingresos pasivos
                        </Card.Text>
                    </Card.Body>
                </Card>
            </SwiperSlide>
        </Swiper>
    );
}
