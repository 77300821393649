import React from "react";
import NavReactPG from "../components/NavReactPG";
import PreguntasContenido from "../components/PreguntasContenido";

export const Preguntas = () => {
  return (
    <div>
      <NavReactPG />
      <PreguntasContenido />
    </div>
  );
};
