import React from "react";
import { Image } from "react-bootstrap";
import bannerHome from "../assets/img/banner.png";
import bannerHomeCel from "../assets/img/bannerhome-cel.png";
import { BotonContacto } from "./BotonContacto";

export const BannerHome = () => {
  return (
    <div className=" ">
      <div className="d-none d-sm-block"> <Image className="w-100" src={bannerHome} alt="banner" fluid /></div>
      <div className="d-sm-none d-block"> <Image className="w-100" src={bannerHomeCel} alt="banner" fluid /></div>
      <div className="posi-boton">
        <BotonContacto />
      </div>
    </div>
  );
};
