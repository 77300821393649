import { BannerHome } from '../components/BannerHome'
import { BannerHome2 } from '../components/BannerHome2'
import { Contacto } from '../components/Contacto'
import Enacointips from '../components/Enacointips'
import Enterate from '../components/Enterate'
import Inspiran from '../components/Inspiran'
import NavbarReact from '../components/NavReact'
import Novedades from '../components/Novedades'
import NuestrosCursos from '../components/NuestrosCursos'
import { ProximosEventos } from '../components/ProximosEventos'

export const Home = () => {
  return (
    <div className='color-negro-fondo'>
      <NavbarReact />
      <BannerHome />
      <BannerHome2 />
      <ProximosEventos />
      <NuestrosCursos />
      <Enacointips />
      <Enterate />
      <Inspiran />
      <Novedades />  
      <Contacto />   
    </div>
  )
}
