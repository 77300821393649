import React from "react";
import { Image } from "react-bootstrap";
import tilde from "../assets/img/tilde.svg";

export default function Aprenderas() {
  return (
    <div className="container text-dark py-5 px-5 ">
      <p className="peso-semibold tamaño-mas-grande"> Lo que aprenderás</p>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="d-flex">
            <div className="mt-1 me-2">
              <Image src={tilde} alt="First slide" />
            </div>
            <p className="tamaño-mas-chico">
              Aprendé cómo funcionan las Criptomonedas.
            </p>
          </div>
          <div className="d-flex">
            <div className="mt-1 me-2">
              <Image src={tilde} alt="First slide" />
            </div>
            <p className="tamaño-mas-chico">
              Dominá el arte de aumentar tus ingresos.
            </p>
          </div>
          <div className="d-flex">
            <div className="mt-1 me-2">
              <Image src={tilde} alt="First slide" />
            </div>
            <p className="tamaño-mas-chico">
              Aprendé de los players del Mercado.
            </p>
          </div>
          <div className="d-flex">
            <div className="mt-1 me-2">
              <Image src={tilde} alt="First slide" />
            </div>
            <p className="tamaño-mas-chico">
              Soporte en línea para los alumnos y manual digital con el
              contenido dictado en la capacitación.
            </p>
          </div>
          <div className="d-flex">
            <div className="mt-1 me-2">
              <Image src={tilde} alt="First slide" />
            </div>
            <p className="tamaño-mas-chico">
              Convertirte en un Experto en Criptomonedas y empezá a construir el
              futuro que buscás.
            </p>
          </div>
          <div className="d-flex">
            <div className="mt-1 me-2">
              <Image src={tilde} alt="First slide" />
            </div>
            <p className="tamaño-mas-chico">
              Sin conocimientos previos te voy a enseñar mis estrategias para
              aumentar tus ingresos a través del trading y a diversificar
              inversiones para que puedas lograr tu libertad financiera
            </p>
          </div>
          <div className="d-flex">
            <div className="mt-1 me-2">
              <Image src={tilde} alt="First slide" />
            </div>
            <p className="tamaño-mas-chico">
              Haremos transmisión en vivo a través de 3 plataformas en
              simultáneo, con cupos limitados para darle a cada participante la
              posibilidad de interactuar conmigo y con mi equipo de trabajo.
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="d-flex">
            <div className="mt-1 me-2">
              <Image className="" src={tilde} alt="First slide" />
            </div>
            <p className="tamaño-mas-chico">
              Lograrás obtener resultados inmediatos, nutriéndote de la misma
              información y herramientas con las que cuentan hoy los grandes
              players del Mercado.
            </p>
          </div>

          <div className="d-flex">
            <div className="mt-1 me-2">
              <Image className="" src={tilde} alt="First slide" />
            </div>
            <p className="tamaño-mas-chico">
              Te ayudaré a introducirte de manera rápida y efectiva al mundo de
              las criptomonedas.
            </p>
          </div>

          <div className="d-flex">
            <div className="mt-1 me-2">
              <Image className=" " src={tilde} alt="First slide" />
            </div>
            <p className="tamaño-mas-chico">
              Te enseñaré estratégias para aumentar tus ingresos a través del
              trading. (Teoría y práctica)
            </p>
          </div>

          <div className="d-flex">
            <div className="mt-1 me-2">
              <Image className="" src={tilde} alt="First slide" />
            </div>
            <p className="tamaño-mas-chico">
              Abordaremos cuestiones de Psicología a la hora de invertir y
              desarrollo personal para emprender.
            </p>
          </div>
          <div className="d-flex">
            <div className="mt-1 me-2">
              <Image className="" src={tilde} alt="First slide" />
            </div>
            <p className="tamaño-mas-chico">
              Se entrega certificado de participación.
            </p>
          </div>
          <div className="d-flex">
            <div className="mt-1 me-2">
              <Image className="" src={tilde} alt="First slide" />
            </div>
            <p className="tamaño-mas-chico">
              El 5% de lo recaudado será donado al comedor infantil "los peques
              de Santa Lucía" ubicado en Monte Grande, Provincia de Buenos
              Aires.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
