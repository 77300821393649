import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import Papa from "papaparse";
import axios from "axios";
import Pagination from "../components/Pagination";
import PaginationMobile from "../components/PaginationMobile";
import NavReactPG from "../components/NavReactPG";

export default function Blog() {
    const [trabajos, setTrabajos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [postsPerPage] = useState(12);
    useEffect(() => {
        setLoading(true);
        const getTrabajos = async () => {
            const response = await axios.get(
                "https://docs.google.com/spreadsheets/d/e/2PACX-1vQRMm-ghjAFX5hptM7c4vKBvnecioXaWKE2Y0SBL4fxsHVoYAcZ9gOF0ToCWxxkDg68TQvHQuFyZOL9/pub?gid=0&single=true&output=csv"
            );

            const imagenes = Papa.parse(response.data, { header: true });
            setTrabajos(imagenes.data);
            setLoading(false);
        };
        getTrabajos();
    }, []);

    // get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = trabajos.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <NavReactPG />
            {loading ? (
                <div className="my-5 text-white  d-flex justify-content-center my-5 p-5">
                    <Spinner
                        className="fs-1 "
                        animation="border"
                        role="status"
                        variant="dark"
                    ></Spinner>
                </div>
            ) : (
                <div className="color-gris-fondo py-5 ">
                    <div className="container">
                        <div className="d-flex flex-wrap">
                            {currentPosts.map((trabajo) => (
                                <Card className="card-novedades mx-auto mb-5">
                                    <Card.Img
                                        className="image-card"
                                        variant="top"
                                        src={trabajo.imagenCuadrada}
                                        alt="Imagen no encontrada"
                                    />
                                    <Card.Body className="m-2 ">
                                        <Card.Title className="mb-4">
                                            {trabajo.titulo.length < 80 ? (
                                                <a
                                                    className="color-negro "
                                                    href={trabajo.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <p className=" peso-semibold2 tamaño-intermedio">
                                                        {trabajo.titulo}
                                                    </p>
                                                </a>
                                            ) : (
                                                <a
                                                    className="color-negro "
                                                    href={trabajo.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <p className="color-negro peso-semibold2 tamaño-intermedio">
                                                        {trabajo.titulo.substring(
                                                            0,
                                                            75
                                                        )}
                                                        ...
                                                    </p>
                                                </a>
                                            )}
                                        </Card.Title>
                                        <Card.Text className=" mt-2 pt-3 mb-4">
                                            {trabajo.descripcion.length <
                                            230 ? (
                                                <p className="color-gris peso-regular2 tamaño-mas-chico">
                                                    {trabajo.descripcion}
                                                    <br />
                                                    <a
                                                        className="color-naranja text-nowrap"
                                                        href={trabajo.link}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Ver articulo
                                                    </a>
                                                </p>
                                            ) : (
                                                <p className="color-gris peso-regular2 tamaño-mas-chico">
                                                    {trabajo.descripcion.substring(
                                                        0,
                                                        225
                                                    )}
                                                    ...{" "}
                                                    <a
                                                        className="color-naranja text-nowrap"
                                                        href={trabajo.link}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Seguir leyendo
                                                    </a>
                                                </p>
                                            )}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            ))}
                        </div>
                        <div className="d-none d-sm-flex justify-content-center">
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={trabajos.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        </div>
                        <div className="d-flex d-sm-none justify-content-center">
                            <PaginationMobile
                                postsPerPage={postsPerPage}
                                totalPosts={trabajos.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
