import React from "react";
import { Button } from "react-bootstrap";

export const BotonProxima = () => {
    return (
        <div>
            <Button className="boton-proxima border-0"><p className="mb-0 tamaño-chico peso-semibold2">Proximamente</p></Button>
        </div>
    );
};
