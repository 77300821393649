import React, { useState } from "react";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import { useRef } from "react";

export default function Enterate() {
  const [validated, setValidated] = useState(false);
  const form = useRef();

  /*funcion para mandar msj al gmail*/
  const sendEmail = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === true) {
      e.stopPropagation();
      emailjs
        .sendForm(
          "service_ica7jps",
          "template_sa3lt3o",
          e.target,
          "8KC6yRrEimujmtfXw"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      Swal.fire({
        icon: "success",
        title: "Mensaje enviado con éxito",
        showConfirmButton: false,
        timer: 2000,
      });
      form.reset();
      setValidated(false); //
    } else {
      setValidated(true);
      Swal.fire({
        icon: "error",
        title: "No se pudo enviar el mensaje.",
      });
    }
  };

  return (
    <div className="fondo-bicolor">
      <div className="primera color-negro-fondo"></div>
      <div className="d-flex justify-content-center">
        <Card className="position-absolute card-enterate mx-auto mb-5 ">
          <Card.Body className="mx-2 mt-2">
            <Card.Title className="text-center pt-4 pt-sm-1 pb-sm-3 pb-1">
              <h3 className="color-negro peso-bold mx-4">
                ¡Entérate primero de <br />
              </h3>
              <h3 className="color-naranja peso-bold">nuestras ofertas!</h3>
            </Card.Title>
            <Card.Text className="tamaño-chico mt-2 color-gris-oscuro peso-regular2 text-center mx-5">
              Si quieres recibir contenido de valor semanal, ofertas, videos,
              invitaciones especiales y ser parte de esta comunidad privada.
              ¡Déjanos tu correo!
            </Card.Text>
            <Form
              ref={form}
              noValidate
              validated={validated}
              onSubmit={sendEmail}
              className="d-flex justify-content-center align-items-center flex-lg-row flex-column mt-lg-5 mt-4"
            >
              <Form.Control
                className="text-enterate mb-lg-0 mb-4"
                placeholder="Ingresa tu correo"
                name="email"
                type="email"
              />
              <Button
                className="ms-lg-4 ms-0 boton-enviar border-0"
                type="submit"
                value="Send"
              >
                <p className="mb-0 tamaño-chico peso-semibold2">Enviar</p>
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <div className="segunda color-blanco-fondo"></div>
    </div>
  );
}
