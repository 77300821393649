import React from "react";
import { Accordion } from "react-bootstrap";

export default function AcordeonPF() {
    return (
        <div className="container margin-acordeon ">
            <Accordion defaultActiveKey="0">
                <Accordion.Item className="row" eventKey="0">
                    <Accordion.Header className="d-none d-lg-block">
                        {/* Desktop */}

                        <div className="tamaño-grande peso-bold color-dorado">
                            01
                        </div>
                        <div className="mx-5 ">
                            <p className="tamaño-grande peso-bold">
                                ¿Tengo que tener experiencia previa para poder
                                inscribirme en el curso?
                            </p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Header className="d-lg-none d-block">
                        {/* celular */}
                        <div>
                            <div className="tamaño-grande peso-bold color-dorado px-4">
                                01
                            </div>
                            <div className="mt-2 px-4">
                                <p className="tamaño-grande peso-bold">
                                    ¿Tengo que tener experiencia previa para
                                    poder inscribirme en el curso?
                                </p>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body className="bg-white ">
                        <p className="peso-regular2 tamaño-chico text-black-50">
                            Si ya eres inversor, la Master Class podría ayudarte
                            a tomar mejores decisiones. Pero si aún no diste el
                            primer paso, el objetivo de este evento en vivo es
                            que sepas cómo empezar, dónde hacerlo y utilices las
                            herramientas para obtener mayores y mejores
                            resultados. Para eso, Ena Andrada va a explicarte
                            todo de forma simple para que puedas entender el
                            contenido de principio a fin.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="row" eventKey="1">
                    <Accordion.Header className="d-none d-lg-block">
                        {/* Desktop */}

                        <div className="tamaño-grande peso-bold color-dorado">
                            02
                        </div>
                        <div className="mx-5 ">
                            <p className="tamaño-grande peso-bold">
                                ¿Debo asistir de forma presencial a la Master
                                Class?
                            </p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Header className="d-lg-none d-block">
                        {/* celular */}
                        <div>
                            <div className="tamaño-grande peso-bold color-dorado px-4">
                                02
                            </div>
                            <div className="mt-2 px-4">
                                <p className="tamaño-grande peso-bold">
                                    ¿Debo asistir de forma presencial a la
                                    Master Class?
                                </p>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p className="peso-regular2 tamaño-chico text-black-50">
                            Actualmente nuestra oferta en cursos y
                            capacitaciones es online o stream en vivo. Para
                            poder acceder a la Master Class el día Miércoles 12
                            de octubre, deberás tener una conexión estable a
                            Internet. Te recomendamos conectarte a través de tu
                            computadora, para poder ver con mayor calidad los
                            gráficos que va a presentar Ena Andrada a lo largo
                            de la transmisión.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="row" eventKey="2">
                    <Accordion.Header className=" d-none d-lg-block">
                        {/* Desktop */}

                        <div className="tamaño-grande peso-bold color-dorado">
                            03
                        </div>
                        <div className="mx-5 ">
                            <p className="tamaño-grande peso-bold">
                                ¿Dónde y cómo abono la inscripción a la master
                                class o a los cursos online?
                            </p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Header className="d-lg-none d-block">
                        {/* celular */}
                        <div>
                            <div className="tamaño-grande peso-bold color-dorado px-4">
                                03
                            </div>
                            <div className="mt-2 px-4">
                                <p className="tamaño-grande peso-bold">
                                    ¿Dónde y cómo abono la inscripción a la
                                    master class o a los cursos online?
                                </p>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p className="peso-regular2 tamaño-chico text-black-50">
                            Debes hacer click en el botón de WhatsApp para que
                            automáticamente seas redireccionado a nuestro medio
                            de comunicación directo donde vas a estar hablando
                            con un miembro del equipo de EnaCoin que te indicará
                            todos los pasos a seguir para abonar tu inscripción.
                            Nuestros métodos de pago son transferencia bancaria,
                            PayPal, usdt y mercado pago.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="row" eventKey="4">
                    <Accordion.Header className="d-none d-lg-block">
                        {/* Desktop */}

                        <div className="tamaño-grande peso-bold color-dorado">
                            04
                        </div>
                        <div className="mx-5 ">
                            <p className="tamaño-grande peso-bold">
                                ¿Cuáles son los medios de comunicación oficiales
                                de Ena Coin?
                            </p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Header className="d-lg-none d-block">
                        {/* celular */}
                        <div>
                            <div className="tamaño-grande peso-bold color-dorado px-4">
                                04
                            </div>
                            <div className="mt-2 px-4">
                                <p className="tamaño-grande peso-bold">
                                    ¿Cuáles son los medios de comunicación
                                    oficiales de Ena Coin?
                                </p>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p className="peso-regular2 tamaño-chico text-black-50 mb-0">
                            Nuestro mail: capacitaciones@enacoin.net
                            <br /> Whatsapp: +54 9 11 2511-7598 <br /> Youtube:
                        </p>
                        <a
                            href="https://youtube.com/channel/UC37HqhROqpJWYV6USLDzKaw"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://youtube.com/channel/UC37HqhROqpJWYV6USLDzKaw
                        </a>

                        <p className="peso-regular2 tamaño-chico text-black-50 mb-0">
                            Instagram:
                        </p>
                        <a
                            href="https://www.instagram.com/enacoin/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://www.instagram.com/enacoin/
                        </a>
                        <p className="peso-regular2 tamaño-chico text-black-50 mb-0">
                            Twitter:
                        </p>
                        <a
                            href="https://twitter.com/oficialenacoin?t=WrVbb3FA93g-Hs1DO83yew&s=08"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://twitter.com/oficialenacoin?t=WrVbb3FA93g-Hs1DO83yew&s=08
                        </a>
                        <p className="peso-regular2 tamaño-chico text-black-50 mb-0">
                            Facebook:
                        </p>
                        <a
                            href="https://www.facebook.com/profile.php?id=100084742819402"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://www.facebook.com/profile.php?id=100084742819402
                        </a>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="row" eventKey="5">
                    <Accordion.Header className="d-none d-lg-block">
                        {/* Desktop */}

                        <div className="tamaño-grande peso-bold color-dorado">
                            05
                        </div>
                        <div className="mx-5 ">
                            <p className="tamaño-grande peso-bold">
                                ¿Qué puedo aprender en los cursos de Enacoin,
                                cuál es el enfoque?
                            </p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Header className="d-lg-none d-block">
                        {/* celular */}
                        <div>
                            <div className="tamaño-grande peso-bold color-dorado px-4">
                                05
                            </div>
                            <div className="mt-2 px-4">
                                <p className="tamaño-grande peso-bold">
                                    ¿Qué puedo aprender en los cursos de
                                    Enacoin, cuál es el enfoque?
                                </p>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p className="peso-regular2 tamaño-chico text-black-50">
                            En EnaCoin nos centramos en educación financiera,
                            criptomonedas y educación emocional. Creemos que es
                            indispensable aprender a gestionar tus emociones y
                            ordenar tus finanzas para alcanzar una vida épica.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="row" eventKey="6">
                    <Accordion.Header className="d-none d-lg-block">
                        {/* Desktop */}

                        <div className="tamaño-grande peso-bold color-dorado">
                            06
                        </div>
                        <div className="mx-5 ">
                            <p className="tamaño-grande peso-bold">
                                ¿Puedo anotarme a una mentoría personalizada?
                            </p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Header className="d-lg-none d-block">
                        {/* celular */}
                        <div>
                            <div className="tamaño-grande peso-bold color-dorado px-4">
                                06
                            </div>
                            <div className="mt-2 px-4">
                                <p className="tamaño-grande peso-bold">
                                    ¿Puedo anotarme a una mentoría
                                    personalizada?
                                </p>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p className="peso-regular2 tamaño-chico text-black-50">
                            Podrás adquirir una mentoría personalizada a través
                            de nuestra línea directa de WhatsApp
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}
