import React from "react";
import brand from "../assets/img/logo.png";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { BotonContacto } from "./BotonContacto";
import { NavHashLink } from "react-router-hash-link";

export default function NavbarReact() {
  return (
    <Navbar
      className="color-negro-fondo nav-detalles"
      variant="dark"
      expand="lg"
    >
      <Container>
        <Navbar.Brand href="/">
          <Image className="nav-logo" src={brand} alt="logo-brand" fluid />
        </Navbar.Brand>
        <div className="d-flex d-lg-none justify-content-end align-items-center">
          <BotonContacto />
          <Navbar.Toggle className="ms-4" aria-controls="basic-navbar-nav" />
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto me-lg-4 me-0">
            <NavHashLink className="ms-auto me-lg-4 me-0 sin-sub" to='/#cursos'>
              <p className="mb-0 color-blanco tamaño-chico">Cursos online</p>
            </NavHashLink>
            <NavHashLink className="ms-auto me-lg-4 me-0 sin-sub" to="/#master">
              <p className="mb-0 color-blanco tamaño-chico">Master Class</p>
            </NavHashLink>
            <NavHashLink className="ms-auto sin-sub" to="/#nosotros">
              <p className="mb-0 color-blanco tamaño-chico">Nosotros</p>
            </NavHashLink>
          </Nav>
        </Navbar.Collapse>
        <div className="d-lg-flex d-none">
          <BotonContacto />
        </div>
      </Container>
    </Navbar>
  );
}
