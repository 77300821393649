import React from "react";
import { Image } from "react-bootstrap";
import banner from "../assets/img/bgpreguntasfrecuentes.png";
import bannerCel from "../assets/img/bannerpreguntascel.png";
import AcordeonPF from "../components/AcordeonPF";

export default function PreguntasContenido() {
  return (
    <div>
      <div>
        <div className="d-none d-sm-block ">
          <Image className="w-100" src={banner} alt="banner" fluid />
        </div>
        <div className="d-sm-none d-block ">
          <Image className="w-100" src={bannerCel} alt="banner" fluid />
        </div>
      </div>
      <div className="d-flex justify-content-center pb-5">
        <AcordeonPF />
      </div>
    </div>
  );
}
