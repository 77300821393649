import axios from "axios";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { Link, useParams } from "react-router-dom";
import { Image, Button, Spinner } from "react-bootstrap";
import NavbarReact from "../components/NavReact";

export default function NovedadesDetallado() {
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const [proyecto, setProyecto] = useState({});
    const getProyecto = async () => {
        setLoading(true);
        const response = await axios.get(
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vQRMm-ghjAFX5hptM7c4vKBvnecioXaWKE2Y0SBL4fxsHVoYAcZ9gOF0ToCWxxkDg68TQvHQuFyZOL9/pub?gid=0&single=true&output=csv"
        );

        const proyectos = Papa.parse(response.data, { header: true });
        const pro1 = proyectos.data;

        const proFiltrado = pro1.filter((pro) => pro.id === params.id);
        setProyecto(proFiltrado[0]);
        setLoading(false);
    };
    useEffect(() => {
        getProyecto();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { titulo, descripcion, imagenBaner, fecha } = proyecto;
    return (
        <div>
            <NavbarReact />
            {loading ? (
                <div className="my-5 text-white  d-flex justify-content-center my-5 p-5">
                    <Spinner
                        className="fs-1"
                        animation="border"
                        role="status"
                        variant="dark"
                    ></Spinner>
                </div>
            ) : (
                <div className="color-gris-fondo color-negro py-5">
                    <div className="container">
                        <div className="d-flex justify-content-center flex-wrap mb-3 mx-5">
                            <h2 className="peso-bold color-negro me-2">
                                Novedades
                            </h2>
                            <h2 className="peso-bold color-dorado ">ENACOIN</h2>
                        </div>
                        <Image
                            className="banner-detalle"
                            src={imagenBaner}
                            alt="Baner-Novedades"
                            fluid
                        />
                        <h3 className="peso-bold my-4 py-3">{titulo}</h3>
                        <p className="tamaño-chico">{fecha}</p>
                        <hr className="opacity-100 mb-4 py-2 w-50" />
                        <p className="mb-0">{descripcion}</p>
                        <Link to="/">
                            <Button className="mt-5 boton-ver-mas2 border-0">
                                <p className="mb-0 tamaño-chico peso-semibold2 text-nowrap">
                                    Volver Atras
                                </p>
                            </Button>
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
}
