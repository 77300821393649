import React from 'react'
import { Image } from 'react-bootstrap'
import banner2 from '../assets/img/banner2.png'
import bannercel from  '../assets/img/banner2-cel.png'

export const BannerHome2 = () => {
  return (
    <div className=''>
      <div className='d-none d-sm-block'><Image className="w-100 mt-5" src={banner2 } alt="banner" fluid /></div>
      <div className='d-sm-none d-block'><Image className="w-100 mt-5" src={bannercel } alt="banner" fluid /></div>
          
    </div>
  )
}
