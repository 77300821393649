import React from "react";
import Comentarios from "./Comentarios";

export default function Inspiran() {
    return (
        <div className="color-blanco-fondo color-negro">
            <div className="container">
                <div className="pb-5 text-center">
                    <p className="espaciado">HISTORIAS QUE</p>
                    <h2 className="peso-semibold color-dorado ">Insipiran</h2>
                </div>
                <Comentarios />
            </div>
        </div>
    );
}
