import React from "react";
import { Button} from "react-bootstrap";
import { NavHashLink } from "react-router-hash-link";


export const BotonContacto = () => {
  return (
    <div>
      <NavHashLink to="/#contacto">
        <Button className="boton-contacto border-0">
          <p className="mb-0 tamaño-chico peso-semibold2">Contacto</p>
        </Button>
      </NavHashLink>
    </div>
  );
};
