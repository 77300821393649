import React from "react";
import { Card, Image } from "react-bootstrap";
import card1 from "../assets/img/card1.png";
import card2 from "../assets/img/card2.png";
import card3 from "../assets/img/card3.png";
import card4 from "../assets/img/card4.png";

import estrella from "../assets/img/star.png";
import { BotonProxima } from "./BotonProxima";
export default function NuestrosCursos() {
    return (
        <div className="color-blanco-fondo color-negro" id="cursos">
            <div className="container">
                <div className="text-center py-5">
                    <p className="espaciado">INVIERTE EN CONOCIMIENTO CON</p>
                    <h2 className="peso-semibold color-dorado mx-3">
                        Nuestros cursos online
                    </h2>
                </div>
                <div className="d-flex flex-wrap">
                    <Card className="card-curso mx-auto mb-5 ">
                        <Card.Img
                            className="image-card"
                            variant="top"
                            src={card1}
                            alt="curso"
                        />
                        <Card.Body className="mx-2 mt-2 ">
                            <Card.Title className="color-negro peso-semibold2">
                                Criptomonedas y trading
                            </Card.Title>
                            <Card.Title className="">
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                            </Card.Title>
                            <Card.Text className="tamaño-mas-chico mt-2 color-gris peso-regular2 ">
                                Vas a aprender todos los conceptos necesarios
                                para introducirte en el mundo de las
                                criptomonedas.
                            </Card.Text>
                            <BotonProxima />
                        </Card.Body>
                    </Card>
                    <Card className="card-curso mx-auto mb-5">
                        <Card.Img
                            className="image-card"
                            variant="top"
                            src={card2}
                            alt="curso"
                        />
                        <Card.Body className="mx-2 mt-2 ">
                            <Card.Title className="color-negro peso-semibold2">
                                Finanzas <br />
                                personales
                            </Card.Title>
                            <Card.Title className="">
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                            </Card.Title>
                            <Card.Text className="tamaño-mas-chico mt-2 color-gris peso-regular2 ">
                                Aprender a ordenar tus finanzas te permitirá
                                minimizar riesgos y potenciar ganancias
                            </Card.Text>
                            <BotonProxima />
                        </Card.Body>
                    </Card>
                    <Card className="card-curso mx-auto mb-5">
                        <Card.Img
                            className="image-card"
                            variant="top"
                            src={card3}
                            alt="curso"
                        />
                        <Card.Body className="mx-2 mt-2 ">
                            <Card.Title className="color-negro peso-semibold2">
                                Finanzas para emprendedores
                            </Card.Title>
                            <Card.Title className="">
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                            </Card.Title>
                            <Card.Text className="tamaño-mas-chico mt-2 color-gris peso-regular2 ">
                                Saber ordenar las finanzas de tu negocio es
                                vital para que puedas hacerlo crecer.
                            </Card.Text>
                            <br/>
                            <BotonProxima />
                        </Card.Body>
                    </Card>
                    <Card className="card-curso mx-auto mb-5">
                        <Card.Img
                            className="image-card"
                            variant="top"
                            src={card4}
                            alt="curso"
                        />
                        <Card.Body className="mx-2 mt-2 ">
                            <Card.Title className="color-negro peso-semibold2">
                                Desarrollo <br /> 
                                personal
                            </Card.Title>
                            <Card.Title className="">
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                                <Image
                                    className="me-2"
                                    src={estrella}
                                    fluid
                                    alt="Estrellas"
                                />
                            </Card.Title>
                            <Card.Text className="tamaño-mas-chico mt-2 color-gris peso-regular2 ">
                                Autoestima y motivación para tolerar la
                                frustración, controlar la ansiedad, y
                                neutralizar preocupaciones.
                            </Card.Text>
                            <BotonProxima />
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}
