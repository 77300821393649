import "./App.css";
import { HashRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import NavReact from './components/NavReact';
import ScrollToTop from './components/ScrollToTop';
import { Home } from './pages/Home';
import { Preguntas } from './pages/Preguntas';
import MasterClass from "./pages/MasterClass";
import Blog from "./pages/Blog";
import PaginaNoEncontrada from "./pages/PaginaNoEncontrada";
import NovedadesDetallado from "./pages/NovedadesDetallado";

function App() {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <HashRouter>
        <ScrollToTop />
        <Routes >
          <Route exact path="/" element={<Home />} />
          <Route path="/masterclass" element={<MasterClass />} />
          <Route path="/preguntasfrecuentes" element={<Preguntas/>} />
          <Route path="/blog" element={<Blog />} />
          <Route path="*" element={<PaginaNoEncontrada />} />
          <Route exact path="/novedades/:id" element={<NovedadesDetallado />} />
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
