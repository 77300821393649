import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import { useRef } from "react";

export default function FormContacto() {
    const [validated, setValidated] = useState(false);
    const form = useRef();

    /*funcion para mandar msj al gmail*/
    const sendEmail = (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            e.stopPropagation();
            emailjs
                .sendForm(
                    "service_ica7jps",
                    "template_94r1edp",
                    e.target,
                    "8KC6yRrEimujmtfXw"
                )
                .then(
                    (result) => {
                        console.log(result.text);
                    },
                    (error) => {
                        console.log(error.text);
                    }
                );
            Swal.fire({
                icon: "success",
                title: "Mensaje enviado con éxito",
                showConfirmButton: false,
                timer: 2000,
            });
            form.reset();
            setValidated(false); //
        } else {
            setValidated(true);
            Swal.fire({
                icon: "error",
                title: "No se pudo enviar el mensaje.",
            });
        }
    };

    return (
        <div className="py-5 container">
            <Form
                className="form-contactanos-escritorio "
                ref={form}
                noValidate
                validated={validated}
                onSubmit={sendEmail}
            >
                <Row className="my-2">
                    <Form.Group
                        className="imputs py-2"
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                    >
                        <Form.Label className="d-flex">
                            <div className="tamaño-mas-chico text-white-50">
                                {" "}
                                Nombre
                            </div>
                            <div className="tamaño-mas-chico color-dorado mx-2">
                                {" "}
                                *{" "}
                            </div>
                        </Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Tu nombre"
                            name="nombre"
                        />
                        <Form.Control.Feedback>
                            Datos Correctos
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            Ingrese su nombre por favor.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                        className="imputs py-2"
                        as={Col}
                        md="6"
                        controlId="validationCustom02"
                    >
                        <Form.Label className="d-flex">
                            <div className="tamaño-mas-chico text-white-50">
                                {" "}
                                Apellido
                            </div>
                            <div className="tamaño-mas-chico color-dorado mx-2">
                                {" "}
                                *{" "}
                            </div>
                        </Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Tu Apellido"
                            name="apellido"
                        />
                        <Form.Control.Feedback>
                            Datos Correctos
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            Ingrese su apellido por favor.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="my-2">
                    <Form.Group
                        className="imputs py-2"
                        as={Col}
                        md="6"
                        controlId="validationCustom03"
                    >
                        <Form.Label className="d-flex">
                            <div className="tamaño-mas-chico text-white-50">
                                {" "}
                                Numero
                            </div>
                            <div className="tamaño-mas-chico color-dorado mx-2">
                                {" "}
                                *{" "}
                            </div>
                        </Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Tu numero de celular"
                            name="cel"
                        />
                        <Form.Control.Feedback>
                            Datos Correctos
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            Ingrese su numero de celular por favor.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                        className="imputs py-2"
                        as={Col}
                        md="6"
                        controlId="validationCustom04"
                    >
                        <Form.Label className="d-flex">
                            <div className="tamaño-mas-chico text-white-50">
                                {" "}
                                Email
                            </div>
                            <div className="tamaño-mas-chico color-dorado mx-2">
                                {" "}
                                *{" "}
                            </div>
                        </Form.Label>
                        <Form.Control
                            required
                            type="email"
                            placeholder="Ingresa tu correo"
                            name="email"
                        />
                        <Form.Control.Feedback>
                            Datos Correctos
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            Ingrese su email por favor.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="my-2">
                    <Form.Group
                        as={Col}
                        md="12"
                        className="text-white"
                        controlId="validationCustom05"
                    >
                        <Form.Label className="d-flex my-3">
                            <div className="tamaño-mas-chico text-white-50">
                                {" "}
                                Mensaje
                            </div>
                            <div className="tamaño-mas-chico color-dorado mx-2">
                                {" "}
                                (opcional)
                            </div>
                        </Form.Label>
                        <Form.Control
                            className=" input-mensaje my-2 py-2"
                            as="textarea"
                            type="text"
                            placeholder="Escribe tu consulta aquí..."
                            name="msj"
                        />
                    </Form.Group>
                </Row>
                <Button
                    className="boton-contacto border-0 mt-3"
                    type="submit"
                    value="Send"
                >
                    <p className="mb-0 tamaño-chico peso-semibold2">Contacto</p>
                </Button>
            </Form>
        </div>
    );
}
