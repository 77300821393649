import React from "react";
import NavReactPG from "../components/NavReactPG";

export default function PaginaNoEncontrada() {
  return (
    <div>
      <NavReactPG />
      <div className="container">
        <div>
          <h3>PaginaNoEncontrada</h3>
        </div>
        <div className="my-5">
          <a  href="/">Regresar al home</a>
        </div>
      </div>
    </div>
  );
}
