import React from "react";
import NavReactPG from "../components/NavReactPG";
import BannerMasterClass from "../components/BannerMasterClass";
import Aprenderas from "../components/Aprenderas";
import NuestrosCursos from "../components/NuestrosCursos";

export default function MasterClass() {
  return (
    <div>
      <NavReactPG />
      <BannerMasterClass />
      <Aprenderas />
      <NuestrosCursos />
    </div>
  );
}
